import { call, takeLatest, put } from 'redux-saga/effects'
import graphql from 'services/graphql'
import api from 'services/api'
import {
  errorMessageFromGraphQL,
  successMessage,
  warningMessage,
  deletedMessage,
  savedMessage,
} from 'config/toast'

import {
  FAILED_GET_BOOKING,
  FAILED_GET_BOOKINGS,
  FAILED_GET_MESSAGES,
  FAILED_GET_NEW_BOOKINGS_COUNT,
  FAILED_PERSIST_BOOKING,
  FAILED_DELETE_BOOKING,
  RECEIVE_GET_BOOKING,
  RECEIVE_GET_BOOKINGS,
  RECEIVE_GET_MESSAGES,
  RECEIVE_GET_NEW_BOOKINGS_COUNT,
  RECEIVE_PERSIST_BOOKING,
  REQUEST_GET_BOOKING,
  REQUEST_GET_BOOKINGS,
  REQUEST_GET_MESSAGES,
  REQUEST_GET_NEW_BOOKINGS_COUNT,
  REQUEST_PERSIST_BOOKING,
  REQUEST_SEND_MESSAGE,
  REQUEST_DELETE_BOOKING,
} from 'redux/actions/booking'

import { NOTIFY } from 'redux/actions/notification'

import { BOOKING_QUERY, BOOKINGS_QUERY, NEW_BOOKINGS_COUNT_QUERY } from 'graphql/queries/booking'
import { BOOKING_MUTATION } from 'graphql/mutations/booking'

function* getBooking(action) {
  try {
    const data = yield call(graphql.query, BOOKING_QUERY, { id: action.id })

    yield put({ type: RECEIVE_GET_BOOKING, data: data.booking })
  } catch (error) {
    yield put({ type: FAILED_GET_BOOKING, error })
    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getBookings(action) {
  try {
    const data = yield call(graphql.query, BOOKINGS_QUERY, {
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      ...(action.filters
        ? {
            filters: action.filters.map((filter) =>
              filter.field === 'date'
                ? {
                    ...filter,
                    value: JSON.stringify(filter.value),
                  }
                : filter
            ),
          }
        : {}),
      ...(action.sorting ? { sorting: action.sorting } : {}),
    })

    yield put({
      type: RECEIVE_GET_BOOKINGS,
      data: data.bookings.data,
      pageNumber: action.pageNumber,
      totalCount: data.bookings.total,
    })
  } catch (error) {
    yield put({ type: FAILED_GET_BOOKINGS, error })
    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getMessages(action) {
  try {
    const payload = action.data
    const response = yield call(api.get, `/messages/${payload.bookingId}`)

    yield put({ type: RECEIVE_GET_MESSAGES, data: response?.data })
  } catch (error) {
    yield put({ type: FAILED_GET_MESSAGES, error })
  }
}

function* getNewBookingsCount() {
  try {
    const data = yield call(graphql.query, NEW_BOOKINGS_COUNT_QUERY)

    yield put({
      type: RECEIVE_GET_NEW_BOOKINGS_COUNT,
      data: data.newBookingCount.count,
    })
  } catch (error) {
    console.log(error)
    yield put({ type: FAILED_GET_NEW_BOOKINGS_COUNT, error })
    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* persistBooking(action) {
  try {
    delete action.data.__typename

    const data = yield call(graphql.mutate, BOOKING_MUTATION, action.data)

    yield put({
      type: RECEIVE_PERSIST_BOOKING,
      data: data.booking,
    })

    const notification = successMessage(savedMessage('booking'))
    yield put({ type: NOTIFY, notification })
  } catch (error) {
    yield put({ type: FAILED_PERSIST_BOOKING, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* deleteBooking(action) {
  try {
    yield call(api.delete, `/booking/${action.id}`)

    const notification = warningMessage(deletedMessage('booking'))
    yield put({ type: NOTIFY, notification })
  } catch (error) {
    yield put({ type: FAILED_DELETE_BOOKING, error })
    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* sendMessage(action) {
  try {
    const payload = action.data
    yield call(api.post, `/messages`, payload)
    yield put({ type: REQUEST_GET_MESSAGES, data: { bookingId: payload.booking_id } })
  } catch (error) {
    const notification = errorMessage(`Could not send a message 😱`)
    yield put({ type: NOTIFY, notification })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_BOOKING, getBooking)
  yield takeLatest(REQUEST_GET_BOOKINGS, getBookings)
  yield takeLatest(REQUEST_GET_MESSAGES, getMessages)
  yield takeLatest(REQUEST_GET_NEW_BOOKINGS_COUNT, getNewBookingsCount)
  yield takeLatest(REQUEST_PERSIST_BOOKING, persistBooking)
  yield takeLatest(REQUEST_SEND_MESSAGE, sendMessage)
  yield takeLatest(REQUEST_DELETE_BOOKING, deleteBooking)
}
