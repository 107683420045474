export const CUSTOMER_MUTATION = `
mutation(
  $id: ID
  $address: [AddressInput]
  $businessName: String
  $email: String!
  $financeEmail: String!
  $firstName: String!
  $isBusiness: Boolean
  $lastName: String!
  $phone: String!
) {
  customer(
    id: $id
    address: $address
    businessName: $businessName
    email: $email
    financeEmail: $financeEmail
    firstName: $firstName
    isBusiness: $isBusiness
    lastName: $lastName
    phone: $phone
  ) {
    id
    address {
      id
      city
      country
      housenumber
      streetname
      zipcode
    }
    businessName
    email
    financeEmail
    firstName
    isBusiness
    lastName
    phone
  }
}
`
