import {call, takeLatest, put} from 'redux-saga/effects'
import { push } from 'react-router-redux'
import graphql from 'services/graphql'
import api from 'services/api'

import {
  FAILED_GET_INVOICE,
  FAILED_GET_INVOICES,
  RECEIVE_GET_INVOICE,
  RECEIVE_GET_INVOICES,
  REQUEST_GET_INVOICE,
  REQUEST_GET_INVOICES,
} from 'redux/actions/invoice'

import { NOTIFY } from 'redux/actions/notification'

import {INVOICE_QUERY, INVOICES_QUERY} from 'graphql/queries/invoice'
import {CREATE_INVOICE_MUTATION, INVOICE_MUTATION} from 'graphql/mutations/invoice'

function* getInvoice(action) {
  try {
    const data = yield call(graphql.query, INVOICE_QUERY, { id: action.id })

    yield put({ type: RECEIVE_GET_INVOICE, data: data.invoice })
  } catch(error) {
    yield put({ type: FAILED_GET_INVOICE, error })
  }
}

function* getInvoices(action) {
  try {
    const data = yield call(graphql.query, INVOICES_QUERY, {
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      ...(action.filters ? { filters: action.filters } : {}),
      ...(action.sorting ? { sorting: action.sorting } : {}),
    })

    yield put({
      type: RECEIVE_GET_INVOICES,
      data: data.invoices.data,
      pageNumber: action.pageNumber,
      totalCount: data.invoices.total,
    })
  } catch(error) {
    yield put({ type: FAILED_GET_INVOICES, error })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_INVOICE, getInvoice)
  yield takeLatest(REQUEST_GET_INVOICES, getInvoices)
}
